import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/addFav";
import commonAPIService from "../../common/js/common-api-service";
import adminService from "./admin-service";
import AppPartComp from "../app-part-comp.vue";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      businessList: [],
      selectedBusiness: [],
      search: "",
      partClassList: [],
      selectedPartClass: [],
      active: true,
      partHeaders: [
        { text: 'Part Number', value: 'Part_Number', class: 'primary customwhite--text' },
        { text: 'Description', value: 'Description', class: 'primary customwhite--text' },
        { text: 'Project', value: 'Project', class: 'primary customwhite--text' },
        { text: 'Class', value: 'Class', class: 'primary customwhite--text' },
        { text: 'Available Qty', value: 'available_qty', class: 'primary customwhite--text' },
        { text: 'Active', value: 'IsActive', class: 'primary customwhite--text' },
        { text: '', value: 'actions', class: 'primary customwhite--text' },
      ],
      partList: [],
      businessTableData: [],
      totalRecords: 0,
      compCalled: false,
      name: "",
      partId: "",


    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.getPartClass()
    this.getBusinessData()
  },
  methods: {
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset Function
    resetFunction() {
      this.clearSearch()
    },
    // to check if action permission is there or not
    actionSetter(action) {
      return Utility.actionSetter(this.actionList, action)
    },
    //Create Label based on number of Labels entered
    // get business data
    async getBusinessData() {
      let businessData = await commonAPIService.getBusiness("get", this.userId);
      this.businessList = businessData.map((x) => ({
        bu_id: x.BuKey,
        business: x.Business,
      }));
      this.businessTableData = this.businessList.filter((x) => x.bu_id != 0)
    },
    //get part class
    async getPartClass() {
      let partClassData = await adminService.getPartClass("get", this.userId)
      this.partClassList = partClassData
    },
    // get part Details
    getPartDetails(id) {
      this.compCalled = true;
      this.name = "Update Part"
      this.partId = id
    },
    // clearSearch
    clearSearch() {
      this.selectedBusiness = [];
      this.selectedPartClass = [];
      this.active = true;
      this.search = "";
      this.partList = [];
      this.totalRecords = 0
    },
    async getSearchResults() {

      let searchData = {
        user_id: this.userId,
        SearchStr: this.search,
        bu_keys: this.selectedBusiness,
        class_keys: this.selectedPartClass,
        IsActive: this.active,
      }
      let searchObj = { json: JSON.stringify(searchData) }
      let searchResult = await adminService.postPartSearchResult("post", searchObj, false)
      if (searchResult?.length > 0) {
        this.partList = searchResult
        this.totalRecords = this.partList?.length
      }
      else {
        this.partList = []
        this.totalRecords = 0

      }

    },
    onClickNewPart() {
      this.compCalled = true;
      this.name = "New Part"
    },
    closeDialog() {
      this.compCalled = false;
      this.getSearchResults()
    }
  },
  components: {
    breadcrumbComp,
    AppPartComp
  },
};
